import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OfferState, offerAdapter } from './offer.state';
import { Offer } from '@models';

export const OFFER_STATE_NAME = 'offers';
const selectOfferState = createFeatureSelector<OfferState>(OFFER_STATE_NAME);
const offerSelector = offerAdapter.getSelectors();
const selectOffers = createSelector(selectOfferState, offerSelector.selectAll);
const selectOfferEntities = createSelector(
  selectOfferState,
  offerSelector.selectEntities
);
const selectOfferLoading = createSelector(
  selectOfferState,
  state => state.isLoading
);
const selectOfferById = (id: Offer['id']) =>
  createSelector(selectOfferEntities, offers =>
    offers ? offers[id] : undefined
  );
export default {
  selectOfferById,
  selectOffers,
  selectOfferLoading,
};
