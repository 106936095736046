import { Organization, Offer } from '@models';
import { Update } from '@ngrx/entity';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const offerActions = createActionGroup({
  source: 'Offer page',
  events: {
    Update: props<{
      data: Partial<Offer> & { image?: File };
      id: Offer['id'];
      ref?: string;
      message?: {
        success?: string;
        error?: string;
      };
    }>(),
    'Update Offer Success': props<{ data: Update<Offer> }>(),
    Delete: props<{
      id: Offer['id'];
      organizationId: Organization['id'];
    }>(),
    'Delete Offer Success': props<{ id: Offer['id'] }>(),
    archive: props<{
      id: Offer['id'];
    }>(),
    'archive Offer Success': props<{ id: Offer['id'] }>(),
    create: props<{ data: Offer & { image?: File }; ref?: string }>(),
    'Create Offer Success': props<{ offer: Offer }>(),
    'fetch Offers': emptyProps(),
    'fetch Offers Success': props<{ offers: Offer[] }>(),
    'fetch Offers Failed': props<{ error: string }>(),
    'update loading': props<{ isLoading: boolean }>(),
    'toggle status': props<{
      id: Offer['id'];
      data: Pick<Offer, 'status'>;
      message: { success: string; error: string };
    }>(),
  },
});
