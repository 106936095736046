import { Offer } from '@models/OfferModel';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export interface OfferState extends EntityState<Offer> {
  isLoading: boolean;
}
export const offerAdapter = createEntityAdapter<Offer>();
export const OfferInitialState: OfferState = offerAdapter.getInitialState({
  isLoading: false,
});
